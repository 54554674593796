import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"

const categoryTemplate = props => {
	const {
		data: {
			wpgraphql: { category }
		},
	} = props;
	const { name } = category;

	return (
		<Layout>
			<div>
				<h1>Category: {name}</h1>
			</div>
		</Layout>
	)
}

export default categoryTemplate;

export const pageQuery = graphql`
	query GET_CATEGORY($id: ID!) {
		wpgraphql {
			category(id: $id) {
				id
				name
				slug
			}
		}
	}
`